// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Poppins&display=swap');

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  font-size: 59pt;
  src: url(../public/fonts/Montserrat/Montserrat-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/Montserrat/Montserrat-Medium.ttf) format("woff2");
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/Montserrat/Montserrat-Bold.ttf) format("woff2");
  // src: url(../public/fonts/Montserrat/Montserrat-SemiBold.ttf) format("woff2");
}

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: 600;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/auction-fonts/Calibri.ttf) format("woff2");
}

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: bold;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/auction-fonts/CalibriBold.ttf) format("truetype");
}

@font-face {
  font-family: "FHACondFrenchNC";
  font-style: normal;
  font-weight: 600;
  font-size: 59pt;
  font-display: swap;
  src: url(../public/fonts/auction-fonts/fhacondfrenchnc-webfont.woff)
      format("woff"),
    url(../public/fonts/auction-fonts/FHACondFrenchNC.otf) format("opentype");
}

* {
  box-sizing: border-box;
  font-family: "Montserrat" !important;
}

$success-color: #1478dd;

.bg-none {
  background-color: transparent;
}

.bx-shadow {
  box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 10px 29px -11px rgba(0, 0, 0, 0.75);
}

a {
  color: black !important;
}

a:hover {
  color: #0d6efd !important;
}

.ghost-bg {
  color: $success-color !important;
  border: 2px solid $success-color !important;
  font-weight: bold !important;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

::placeholder,
input.form-control,
textarea.form-control {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5a5a5a;
}

.line-space {
  letter-spacing: 2px !important;
}

.btn-ghost {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-ghost:hover {
  box-shadow: rgb(4 17 29 / 25%) 1px 2px 10px 0px;
}

.trans {
  transition: all 0.2s linear;
}

.ghostBorder {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  // width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-ghost-small {
  border-radius: 10.6px;
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
}

.btn-ghost-sharp {
  background-color: transparent;
  color: black;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
}

.black-ghost-btn {
  background-color: transparent;
  color: black;
  border: 2px solid #1c1f23 !important;
  font-weight: bold;
  font-size: 13.34px;
}

.btn-ghost-unchecked {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: transparent;
  color: black;
  border: 2px solid #d2d2d2 !important;
  font-weight: bold;
  width: 100% !important;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.nrml-wdth {
  border-radius: 10.6px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: $success-color;
  color: white;
  border: 2px solid $success-color !important;
  font-weight: bold;
  font-size: 13.34px;
  letter-spacing: 2px;
  width: fit-content !important;
}

.btn-green {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: $success-color !important;
  color: white !important;
  border: 2px solid $success-color !important;
  font-weight: bold;
  width: 100%;
  font-size: 13.34px;
  letter-spacing: 2px;
}

.btn-grey {
  border-radius: 5px;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  background-color: #d2d2d2 !important;
  color: black;
  border: 2px solid #d2d2d2 !important;
  font-weight: bold;
  width: 100%;
  font-size: 13.34px;
  letter-spacing: 2px;
}

h4.fleatoName {
  color: #1478dd !important;
}

.dJLIRr .ais-SearchBox-submit {
  float: right;
  border: none;
  padding: 6px 2px !important;
  background: #f1f1f1 !important;
  color: black;
  font-size: 1rem;
  border-radius: 5px !important;

  cursor: pointer;
}

.bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 600 !important;
  font-size: 15px;
}

.bold-text {
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-black,
btn-black:hover {
  background: #000;
  border: 1px solid #000;
}

.blue-bg,
.blue-bg {
  background: #1478dd !important;
}

.black-bg {
  background-color: #1c1f23;
}

.white-bg {
  background: #ffffff;
}

.border-blue {
  border: 1px solid #1478dd !important;
}

.border-grey {
  border: 1px solid #eaeaea;
}

.text-blue,
.text-blue:hover {
  color: #1478dd !important;
}

.dJLIRr .ais-SearchBox-submit:hover {
  color: #1478dd !important;
}

.dJLIRr .ais-SearchBox-input {
  padding: 6px 10px;
  font-size: 1rem;
  border: 0;
  float: left;
  width: 92%;
  background: #f1f1f1 !important;
  border-radius: 5px !important;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.jodit-status-bar {
  visibility: hidden;
}

.btn-ghost:hover {
  color: $success-color;
}

.btn-ghost-unchecked:hover {
  color: $success-color;
}

//Google tag manager css trigger classes
.gtm-walletpopup {
}

.gtm-loginpopup {
  .fs-22 {
    font-size: 22px;
  }
}

.gtm-wallet-setup {
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Montserrat" !important;
  font-style: normal;
  font-weight: 300;
  color: #2c2c2c;
  font-size: 0.95rem;
  background: #ffffff;
}

table td,
table th {
  color: #2c2c2c;
}

a {
  color: #000 !important;
  // font-weight: bold !important;
  font-size: 15px !important;
  text-decoration: none !important;

  // letter-spacing: 2px !important;
  &:hover {
    color: #1478dd !important;
  }
}

.no-linespace {
  letter-spacing: 0 !important;
  font-size: 14px !important;
}

.link {
  color: #1478dd !important;
}

h1 {
  font-size: 2.2rem;
  font-weight: 600 !important;
}

h2 {
  font-size: 1.8rem !important;
  color: rgb(0, 0, 0);
  font-weight: 600;
}

h3 {
  font-size: 1.2rem;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

h4 {
  font-size: 1rem !important;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

.font-bold {
  font-weight: 700 !important;
}

.font-md-bold {
  font-weight: 500;
}

.font-semi-bold {
  font-weight: 600;
}

.text-normal {
  color: #000000;
  position: relative !important;
  left: 30px !important;
}

.text-normal::before,
.text-normal::after {
  display: none;
}

.white-bg {
  background-color: rgb(255, 255, 255);
}

.hover-green-bg {
  &:hover {
    background-color: #20db89 !important;
  }
}

div.rce-mbox {
  border-radius: 25px 25px 25px 0px !important;
  background: white !important;
  padding: 20px;
  border: 2px solid #1478dd !important;
}

div.rce-mbox.rce-mbox-right {
  border-radius: 25px 25px 0px 25px !important;
  background: white !important;
  padding: 20px;
  border: 2px solid #1478dd;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e4edff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1478dd;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1478dd;
}

.text-white {
  color: #fff;
}

.text-error {
  color: #f00;
}

.text-white-light {
  color: #d3d3d3;
}

.text-blue {
  color: #2081e2 !important;
}

.font-default {
  font-size: 1rem;
}

.font-md {
  font-size: 0.9rem;
}

.font-sm {
  font-size: 0.8rem;
}

.max-w-100 {
  max-width: 100% !important;
}

.cursor-pointer {
  cursor: pointer;
}

.border {
  border: none;
}

.btn-light {
  background-color: #dbdbdb !important;
}

.border-green {
  border-radius: 10px;
  background-color: #20db89;
  color: white;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
}

.border-orange {
  border-radius: 10px;
  background-color: #ffc700;
  color: white;
  letter-spacing: 2px;
  font-size: 15px;
  font-weight: bold;
}

.menu li {
  a {
    font-weight: 600;
    color: #000 !important;
    font-size: 0.9rem;

    &:hover {
      text-decoration: none;
    }

    @media (max-width: 1199px) {
      font-size: 0.8rem;
    }
  }
}

.carousal-info {
  a {
    font-weight: bold !important;
    color: #1478dd !important;
    font-size: 20px !important;
  }
}

.main-content {
  background-color: #f5f5f5 !important;
}

.mt-15 {
  margin-top: 0.35rem !important;
}

.ais-Pagination {
  margin-top: 1em;
}

.left-panel {
  float: left;
  width: 250px;
}

.right-panel {
  margin-left: 260px;
}

.ais-NumericMenu-labelText {
  margin-left: 10px;
}

.ais-InstantSearch {
  max-width: 960px;
  overflow: hidden;
  margin: 0 auto;
}

.ais-Hits {
  display: flex;
  // flex-wrap: wrap;
  // padding: 0;
}

.ais-Hits-list {
  display: flex;
  width: 500 !important;
  overflow: hidden;
  // flex-wrap: wrap;
  // flex-direction: row;
}

.ais-Hits {
  // @media (max-width: 1199px) {
  //   height: 1600px;
  //   overflow: auto;
  // }
}

@media (min-width: 992px) {
  .productDetailMain {
    // height: 640px !important;
  }

  .productDetails {
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .productDetails::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 425px) {
  .artistData {
    margin-top: 0 !important;
    padding-left: 2rem !important;
  }
}

@media (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

.ais-Hits-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;

  @media (max-width: 1200px) {
    width: 16.6666vw;
  }

  @media (max-width: 768px) {
    width: 27.777vw;
  }

  @media (max-width: 640px) {
    width: 41.6667vw;
  }

  @media (max-width: 424px) {
    width: 83.3333vw;
  }
}

.ais-InfiniteHits-list {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

li::marker {
  color: transparent;
}

.w-auto {
  width: auto !important;
}

@media (max-width: 600px) {
  .fs-small-14 {
    font-size: 14px !important;
  }

  .w-small-600 {
    width: 600px;
  }

  .fw-small-normal {
    font-weight: normal !important;
  }
}

.space-no-wrap {
  white-space: nowrap;
}

.errorMessage {
  font-weight: 400;
  margin-left: 0px;
  color: #ea321c;
  font-size: 0.8rem;
}

.ais-InfiniteHits-item:not(.artist-card-layer) {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.1s linear;

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (min-width: 600px) {
    width: 50%;
  }

  @media (min-width: 920px) {
    width: 33.333%;
  }

  @media (min-width: 1280px) {
    width: 25%;
  }
}

.artist-store-tab {
  .ais-InfiniteHits {
    width: 100%;

    .ais-InfiniteHits-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .ais-InfiniteHits-item:not(.artist-card-layer) {
        transition: all 0.1s linear;

        @media (max-width: 600px) {
          width: 100%;
        }

        @media (min-width: 600px) {
          width: 50%;
        }

        @media (min-width: 1280px) {
          width: 33.33%;
          max-width: 320px;
        }
      }
    }
  }
}

.ais-CurrentRefinements-label {
  display: none;
}

.ais-CurrentRefinements-category {
  border-radius: 5px;
}

.ais-CurrentRefinements-delete {
  border-radius: 50%;
}

.ais-InfiniteHits-item:hover {
  transform: translateY(-5px);
}

.recent-drop {
  .ais-InfiniteHits {
    height: 1640px;
    overflow: hidden;

    .ais-InfiniteHits-list {
      display: flex;

      .ais-InfiniteHits-item {
        justify-content: center;

        .card-wrapper {
          width: 100% !important;

          .card {
            overflow: hidden;
          }
        }
      }
    }
  }
}

.w-48 {
  width: 48% !important;
}

@media screen and (min-width: 768px) {
  .recent-drop {
    .ais-InfiniteHits {
      height: 820px !important;
    }
  }

  .tab-content {
    .ais-InfiniteHits {
    }
  }

  .w-md-48 {
    width: 48% !important;
  }
}

@media screen and (min-width: 920px) {
  .recent-drop {
    .ais-InfiniteHits {
      height: 410px !important;
      overflow: hidden;

      .ais-InfiniteHits-list {
        display: flex;

        .ais-InfiniteHits-item {
          justify-content: center;

          .card-wrapper {
            width: 100% !important;

            .card {
              overflow: hidden;
            }
          }
        }
      }
    }
  }
}

// Modal Css
.bidModal,
.share-modal,
.profileModal,
.redeemModal,
.addressModal {
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;
}

.loginModal {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;

  .react-tel-input .form-control {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.circleModal {
  position: absolute;
  width: 300px;
  height: 300px;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  padding: 0;
  border: 0;
  outline: none;
  border-radius: 50% !important;
  box-shadow: rgb(0 0 0 / 32%) 0px 3px 6px;
  background-color: #fff;
  overflow: hidden;
}

.circleModal:focus {
  border: 0;
  outline: none;
}

.bidModal {
  width: 46%;
}

.profileModal {
  width: 90%;
}

@media screen and (min-width: 720px) {
  .profileModal {
    width: 60%;
  }

  .circleModal {
    width: 500px;
    height: 500px;
  }
}

.ReactModal__Overlay--after-open {
  z-index: 12;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

@media (max-width: 991px) {
  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo svg {
      max-width: 25px;
    }
  }

  .bidModal {
    width: 90%;
    height: auto !important;
  }

  .addressModal {
    width: 100%;
  }
}

@media (min-width: 768px) {
  // .ReactModal__Body--open,.ReactModal__Overlay--after-open {
  //   overflow-y: hidden;
  // }
  .h-md-auto {
    height: auto !important;
  }
}

.align-items-stretch {
  align-items: stretch;
}

@media (max-width: 600px) {
  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }

  h3 {
    font-size: 1.1rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }

  h4 {
    font-size: 1rem;
    color: rgb(0, 0, 0);
    font-weight: 700;
  }

  .loginModal {
    width: 90%;
    height: auto !important;
  }
}

@media (max-width: 576px) {
  .loginModal {
    height: 100%;
  }
}

.loginModal {
  border-radius: 24px;
}

header {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.container.py-3.px-lg-4.max-w-100 {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  background-color: white;
  position: relative;
}

input.newsletter-input {
  border: none !important;
  background: #f1f1f1 !important;
  color: #000 !important;
  padding: 0.5rem 1rem 0.5rem 1rem !important;
  width: 362px !important;
  border-radius: 10px;
}

.helper-container .accordion-topic {
  color: #000;
  line-height: 8px;
  padding: 15px;
  margin: 0;
  letter-spacing: 1.5px;
}

.helper-container .accordion-topic:hover {
  color: #1478dd !important;
  line-height: 8px;
  padding: 15px;
  margin: 0;
  letter-spacing: 1.5px;
}

.helper-container .active {
  color: #1478dd !important;
  line-height: 8px;
  width: 100%;
  padding: 15px;
  background-color: #1478dd35;
  border-radius: 0px 25px 25px 0px;
  margin: 0;
  letter-spacing: 1.5px;
}

.help-link.inactive {
  font-size: 20px !important;
  letter-spacing: 3.5px !important;
  color: #000;
}

.help-link.active {
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 3.5px !important;
  color: #1478dd !important;
}

.paypal-buttons {
  z-index: 0;
}

.anchorPara {
  color: blue;
  cursor: pointer;
}

.anchorPara:active {
  color: #1478dd;
}

.loader {
  animation: load 1.1s infinite linear;
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: url("/images/close-circle.png");
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: cover;
}

.alarm {
  animation: tada 1.5s ease infinite;
}

@keyframes tada {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
    transform: scale3d(0.95, 0.95, 0.95) rotate3d(0, 0, 1, -10deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
    transform: scale3d(1, 1, 1) rotate3d(0, 0, 1, 10deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.snooze {
  animation: fade-up 2s infinite forwards;
}

@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

.lightning {
  animation: fade-up 1.5s infinite linear;
}

@keyframes fade-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }

  75% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
    opacity: 0;
  }
}

.categories-list a:hover {
  color: #1478dd !important;
}

.primaryBorder {
  border: 1px solid #4ca5ff !important;
}

@media only screen and (max-width: 600px) {
  .categories-btn {
    top: 80px;
    left: 30px;

    .btn {
      font-size: 10px;
    }
  }

  .categories-banner-image {
    height: 200px;
  }
}

@media only screen and (min-width: 600px) {
  .categories-btn {
    top: 180px;
    left: 70px;
  }

  .categories-banner-image {
    height: 400px;
  }
}

@media only screen and (min-width: 768px) {
  .categories-btn {
    top: 160px;
    left: 70px;

    .btn {
      font-size: inherit;
    }
  }

  .categories-banner-image {
    height: 350px;
  }
}

@media only screen and (min-width: 992px) {
  .categories-btn {
    top: 300px;
    left: 110px;

    .btn {
      font-size: inherit;
    }
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .categories-banner-image {
    height: 400px;
  }

  .accessoriesBanner {
    .categories-btn {
      top: 330px;
      left: 50%;
      transform: translate(-25%);
    }
  }

  .paintingBanner {
    .categories-btn {
      top: 330px;
      left: 45%;
      transform: translate(-25%);
    }
  }

  .decorBanner {
    .categories-btn {
      top: 330px;
      left: 60%;
      transform: translate(20%);
    }
  }

  .allBanner {
    .categories-btn {
      top: 330px;
      left: 50%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .categories-btn {
    top: 300px;
    left: 110px;

    .btn {
      font-size: inherit;
    }
  }
}

.btn-white {
  padding: 15px !important;
}

.leaflet-pane {
  z-index: 6 !important;
}

.leaflet-top {
  z-index: 7 !important;
}

.leaflet-popup-content-wrapper {
  background: transparent !important;
}

.leaflet-popup-content {
  margin: 0 !important;
  border-radius: 10px;
}

.leaflet-popup-tip {
  display: none;
}

.leaflet-bottom.leaflet-right {
  z-index: 7;
}

// .hover-image:hover {
//   box-shadow: 0 5px #000;
//   transform: translateY(-5px);
//   border-top-left-radius: 5px;
//   border-top-right-radius: 5px;
//   opacity: 0.85;
// }

video {
  object-fit: cover;
}

.scroll-container {
  .ais-Hits-list {
    overflow: visible;

    .ais-Hits-item {
      margin: 0px;

      @media screen and (min-width: 720px) {
        margin-right: 10px;
        width: 350px;
      }

      .pdt-cover {
        width: 100% !important;
        border-radius: 8px;
      }
    }
  }
}

.related-scroll-container {
  .ais-Hits-list {
    // overflow: hidden;

    flex-wrap: wrap;

    .ais-Hits-item {
      margin: 0px;

      @media screen and (min-width: 720px) {
        margin-right: 10px;
        width: 250px;
      }

      .pdt-cover {
        width: 100% !important;
        border-radius: 8px;
      }
    }
  }
}

.popup-content {
  border-radius: 5px;
  padding: 0.8rem !important;
  width: fit-content !important;
}

.full-screen-content {
  background-color: #000 !important;
  z-index: 2;

  .carousel-slider {
    margin-top: 20px;

    .slider-wrapper {
      height: auto !important;

      .slider {
        .img-fluid {
          width: 80vh;
          height: 80vh;
        }
      }
    }
  }
}

//Artist banner style

.mobileBannerTitle {
  position: absolute;
  z-index: 1;

  h1 {
    color: #fff;
    text-transform: uppercase;
    background-color: #000;
    padding: 0 10px;
  }

  bottom: -50px;
  left: 20px;
  // transform: translate(-50%);
}

.mobileBannerBackground {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.overflowY-hidden {
  overflow-y: hidden;
}

#maxImage {
  height: auto !important;

  .img-fluid {
    height: 100vh !important;
    width: 100vh !important;
    object-fit: contain;
  }
}

@media screen and (max-width: 600px) {
  .shareComponent {
    position: absolute;
    margin-top: 25% !important;
    margin-left: 2%;
  }

  .greyHover {
    width: 100%;
  }
}

.ReactModal__Content {
  border-radius: 24px;
}

.artist-slider-pointer :hover {
  cursor: pointer;
}

@media screen and (min-width: 720px) {
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 340px);
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  }
}

@media screen and (min-width: 768px) {
  .greyHover {
    width: 100%;
  }
}

@media screen and (min-width: 920px) {
  .commission-btn-container {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 300px);
    justify-content: center;
    align-items: center;
    grid-gap: 20px;
  }

  .greyHover {
    width: 49%;
  }
}

.hover-blue:hover {
  color: #1478dd;
}

@media (min-width: 1380px) {
  .artistCollection {
    li {
      width: 330px !important;
    }
  }
}

.about-nft .badge {
  cursor: pointer;
  background: #d4d9de;
  border-radius: 50%;
  padding: 5px;
  line-height: 0;
  margin-left: 3px;

  svg {
    fill: #ffffff;
  }
}

.seller-profile {
  .blue-border {
    border: 2px solid #1478dd !important;
  }

  .active-tab {
    border-right: 5px solid #1478dd;
  }

  .category-accordion {
    .accordion {
      .accordion-header {
      }

      .accordion-collapse {
        .accordion-items {
          ul {
            .accordion-name {
              width: auto;
            }

            li {
              padding: 10px;
              margin: 10px 0;
              background-color: #1478dd;
              border-radius: 30px;
              color: #fff;
            }
          }
        }
      }
    }
  }
}

.banner-container {
  height: 150px;

  .seller-profile-img {
    height: 150px;
    width: 150px;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 25%);
    z-index: 3;

    .profile-image {
      height: 150px;
      width: 150px;
      object-fit: cover;
    }
  }
}

.greyHover:hover {
  background-color: #f5f5f5;
}

.mediumSize {
  font-size: 16px;
}

.deohmg .radio-select .dropdown-trigger .tag-item {
  width: 90% !important;
}

@media (min-width: 768px) {
  .deohmg .radio-select .dropdown-trigger .tag-item {
    width: 95% !important;
  }
}

.close-btn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg {
    margin: 0 10px;
    padding: 1px;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid #000;
    width: 20px;
    height: 20px;
  }
}

.underLined {
  border-bottom: 2px solid #d2d2d2;
}

.list-items {
  cursor: pointer;
}

.active-list-item {
  background-color: #f1f1f1;
}

.list-items:hover {
  background-color: #f1f1f1;
}

.react-toggle-track {
  background-color: #d2d2d2 !important;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #d2d2d2;
}

.react-toggle--checked .react-toggle-track {
  background-color: #1478dd !important;
}

.react-toggle-thumb {
  box-shadow: none !important;
}

.react-toggle-thumb {
  border: none !important;
}

.primary-border {
  left: 0;
  top: 0;
  border: 0.25em solid #1478dd;
  border-bottom-color: transparent !important;
  border-left-color: transparent;
  animation: 1.2s linear infinite spinner-border;
}

.merchandise {
  .accordion-header {
    .accordion-button {
      padding: 0;
    }

    .accordion-button::after {
      margin-right: 5%;
    }
  }
}

.hyJONO:hover {
  box-shadow: none;
}

// .pdt-cover:hover {
//   box-shadow: rgb(4 17 29 / 25%) 0px 0px 8px 0px;
//   transition: all 0.1s ease 0s;

// }

.title-tip[data-title]:hover {
  position: relative;
}

.title-tip[data-title]:hover:before {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99999;
  content: attr(data-title);
  margin-top: 0.5em;
  padding: 0.5em;
  width: 200px;
  overflow: hidden;
  word-wrap: break-word;
  font-size: inherit;
  color: #fff;
  text-align: center;
  background-color: #222;
  box-sizing: border-box;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.title-tip[data-title]:hover:after {
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 99999;
  content: "";
  margin-left: -0.125em;
  width: 0;
  height: 0;
  border: 0.25em dashed transparent;
  border-bottom: 0.25em solid #222;
  font-size: inherit;
}

.circular-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nowrap {
  white-space: nowrap;
}

.grey-text {
  color: #808f99 !important;
}

.small-text {
  font-size: 14px;
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-black {
    color: #000 !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }
}

.white-space-nowrap {
  white-space: nowrap;
}

.creater-list p {
  margin-bottom: 0;
}

.identicon {
  border-radius: 50%;
  border: 1px solid #dee2e6 !important;
}

.small-card {
  width: 100%;
}

.medium-card {
  width: 100%;
}

.medium-product-card {
  width: 100%;
}

.medium-product-card:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.large-card {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .small-card {
    width: 32%;
  }

  .vertical-md-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .medium-card {
    width: 32%;
  }

  .medium-product-card {
    width: 48%;
  }
}

@media screen and (min-width: 992px) {
  .small-card {
    width: 48%;
  }

  .large-card {
    width: 48%;
  }

  .medium-product-card {
    width: 24%;
  }
}

@media screen and (min-width: 1200px) {
  .commission-btn-container {
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
  }

  .small-card {
    width: 31.9%;
  }

  .medium-product-card {
    width: 32%;
  }
}

.variationModal {
  height: 95%;
  overflow-y: scroll;
}

.proposal-tab {
  p {
    position: relative;
  }

  .active::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: #1478dd;
    position: absolute;
    bottom: -16px;
    left: 0;
  }
}

.wallet-card p:nth-child(1) {
  font-weight: 600;
  font-size: 18px;
}

.discourse-table {
  thead {
    tr {
      border-bottom: 2px solid #dee2e6 !important;
    }
  }
}

.tooltip-inner {
  background-color: #fff;
  color: #000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 400px;
  text-align: left;
  width: 400px;
}

.tooltip-arrow::before {
  border-bottom-color: #fff !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.tooltip.show {
  opacity: initial;
  filter: drop-shadow(0 0px 2px rgba(0, 0, 0, 0.3));
}

.object-fit-cover {
  object-fit: cover;
}

.checkout-table {
  td {
    border-bottom-width: 0;
  }
}

.gap-5 {
  gap: 5;
}

.no-wrap {
  white-space: nowrap;
}

@media screen and (min-width: 920px) {
  .w-lg-100 {
    width: 100% !important;
  }

  .no-lg-gap {
    gap: 0 !important;
  }
}

.artist-carousal {
  .carousel {
    overflow: hidden;
  }

  .carousel-inner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.4);
  }

  .title {
    font-size: 30px;
    color: #fff;
  }

  .carousel-caption {
    top: 50%;
    left: 5%;
    text-align: left;
  }

  .carousel-indicators {
    display: none;
  }

  .carousel-caption,
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 30%;
    bottom: auto;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    height: 4rem;
    width: 4rem;
  }

  @media screen and (min-width: 768px) {
    .title {
      font-size: 60px;
      color: #fff;
    }

    .carousel-caption {
      top: 60%;
      left: 10%;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: 40%;
      bottom: auto;
    }
  }

  @media screen and (min-width: 920px) {
    .carousel-caption {
      top: 40%;
      left: 10%;
      right: none;
    }

    .carousel-control-prev,
    .carousel-control-next {
      top: 30%;
      bottom: auto;
    }

    height: 100vh;
  }

  @media screen and (min-width: 1280px) {
    .carousel-control-prev,
    .carousel-control-next {
      top: 25%;
      bottom: auto;
    }
  }
}

.about-banner {
  .artist-description {
    color: #f6f6f6;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 30px;
    padding: 0 20px;
  }

  .white-ghost-btn {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    color: #ffffff;
    border: 3px solid #ffffff;
    border-radius: 2px 2px 2px 2px;
    padding: 10px 20px 10px 20px;
  }

  .glassmorphic {
    background-color: #03264875;
    text-align: center;
  }

  .glassmorphic-content {
    margin-top: 25%;
  }
}

.blur-layer::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: blur(4px);
  background-image: inherit;
  background-size: cover;
}

.about-artist-title,
.artist-store-title {
  font-size: 25px;
  color: #fff;
}

@media screen and (min-width: 768px) {
  .about-artist-title,
  .artist-store-title {
    font-size: 45px !important;
    color: #fff;
  }

  .overflow-md-hidden {
    overflow: hidden;
  }
}

.artist-store {
  height: 100vh;
  position: relative;

  .artist-store-title {
    z-index: 2;
    position: relative;
  }

  .align-center {
    padding-top: 18%;
    text-align: center;
  }

  .white-btn {
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
    fill: #000000;
    color: #000000;
    background-color: #ffffff;
    border-radius: 1px 1px 1px 1px;
    padding: 10px 20px 10px 20px;
    z-index: 2;
    position: relative;
  }

  .grid-view {
    width: 100%;
    height: 100%;
    background-image: url("https://imgv3.fotor.com/images/videoImage/Fotor-GoArt-ai-art-generator-banner.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.artist-store::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.4);
}

.offer-wrapper {
  background-color: #eaeded;

  .col-sm-4 {
    min-height: 300px;
    background: #fff;
    height: 100%;
    position: relative;
    margin-bottom: 0;
    margin-top: 0;
    overflow: hidden;
    padding-top: 20px;

    @media (min-width: 768px) {
      width: 48.8%;
    }

    @media (min-width: 920px) {
      width: 24%;
    }
  }

  // .offer-card {
  // height: 420px;
  //   margin: 10px
  // }

  .offer-title {
    font-size: 21px;
    font-weight: 700;
    line-height: 27.3px !important;
    padding: 0 20px;
    margin-bottom: 15px;
  }

  .offer-name {
    font-size: 12px;
    line-height: 18px;
    height: 36px;
  }

  .offer-item {
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0 0 36px;
    position: relative;
    width: 50%;
    text-align: center;
  }

  .offer-gallery {
    height: 300px;
    margin-bottom: 44px;
    padding: 0 20px;
  }

  .offer-gallery-row-1,
  .offer-gallery-row-2 {
    height: 50%;
    width: 100%;
    overflow: hidden;
    margin-bottom: 10px;
  }
}

.fs-30 {
  font-size: 30px;
}

.popover-body {
  white-space: normal;
}

.node.tree {
  input {
    display: none;
  }
}

.offer-button-wrapper {
  min-height: 200px;
  background-color: #000;
  background-image: url("../public/images/categories/diwali-banner.png");
  background-repeat: no-repeat;
  background-size: 160% 100%;
  background-position-x: 48%;

  .btn-warning {
    left: 50%;
    bottom: 5px;
    transform: translate(-50%, 0);
    border-radius: 0;
    background: transparent;
  }
}

.popup-container {
  display: grid;
  grid-template-rows: 1fr 3fr auto;
}

.popup-body {
  overflow-y: scroll;
  max-height: 400px;
}

@media screen and (min-width: 768px) {
  .popup-container {
    display: grid;
    grid-template-rows: 1fr 4fr auto;
  }

  .offer-button-wrapper {
    min-height: 200px;
    background-color: #000;
    background-image: url("../public/images/categories/diwali-banner.png");
    background-repeat: no-repeat;
    background-size: 100% 90%;
    background-position-x: 48%;

    .btn-warning {
      left: 50%;
      bottom: 15px;
      transform: translate(-50%, 0);
      border-radius: 0;
      background: transparent;
    }
  }

  .w-md-auto {
    width: auto !important;
  }
}

@media screen and (min-width: 920px) {
  .offer-button-wrapper {
    min-height: 300px;
    background-size: contain;
    background-position-x: center;
  }
}

.close-icon {
  top: 10px;
  right: 10px;
}

.popup-image {
  height: 250px;
}

.popup-title {
  font-weight: 400;
  font-size: 1.2rem;
}

.fw-600 {
  font-weight: 600;
}

.fs-18 {
  font-size: 18px;
}

.popup {
  border: 1px solid #d2d2d2;
}

.public-DraftStyleDefault-block {
  // margin: 0 !important;
}

.setting-navigation {
  .nav-item {
    @media (max-width: 600px) {
      button {
        font-size: 12px;
      }
    }
  }
}

.underlined-text {
  text-decoration: underline !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.fa-bg {
  background-color: #f0553f !important;
}

.fa-btn {
  background-color: #f0553f !important;
  border: 2px solid #f0553f !important;
}

.fa-ghost-btn {
  border: 2px solid #f0553f !important;
}

.fa-ghost-btn:hover {
  color: #f0553f !important;
}

.fa-btn,
.fa-ghost-btn:focus {
  box-shadow: 0 0 0 0.25rem #f0543f57;
}

.fa-color {
  color: #f0553f !important;
}

.outline-none {
  outline: none !important;
}

.circle-font {
  font-family: "FHACondFrenchNC" !important;
}

.popover-header {
  white-space: normal;
}

.offcanvas-body {
  overflow-x: hidden;
}

.active-menu {
  font-size: 18px !important;
  font-weight: bold;
  z-index: 10;
  opacity: 1;
}
.not-active-menu {
  opacity: 0.7;
}

.tb-primary-btn {
  position: relative;
  font-size: 22px;
  padding: 10px 0;
  border-radius: 12px;
}

.tb-secondary-btn {
  font-size: 22px;
  padding: 10px 0;
  border-radius: 12px;
}

.tb-primary-btn::after {
  content: "";
  position: absolute;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-size: contain;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.react-modal-sheet-content {
  input[type="text"],
  input[type="input"],
  input[type="number"] {
    font-size: 20px;
    border-radius: 12px;
    padding: 10px;
  }
}

.offcanvas a {
  font-family: "Calibri" !important;
}

//animations
.focus-in-contract-bck {
  -webkit-animation: focus-in-contract-bck 1s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: focus-in-contract-bck 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-18 13:10:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation focus-in-contract-bck
 * ----------------------------------------
 */
@-webkit-keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

@keyframes focus-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(300px);
    transform: translateZ(300px);
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(12px);
    transform: translateZ(12px);
    -webkit-filter: blur(0);
    filter: blur(0);
    opacity: 1;
  }
}

.options {
  li:hover {
    background-color: #f8f7fa;
  }
}

.f-shrink-none {
  flex-shrink: 0;
}

//auctiontab styles

.rts___tabs {
  padding: 0 !important;
}
.rts___tab {
  margin: 0 !important;
  position: relative !important;
}

.rts___nav___btn svg {
  max-width: unset !important;
}

.rts___btn {
  border-radius: unset !important;
  border: none !important;
}

.rts___tab::after {
  content: "";
  margin: auto;
  height: 3px;
  background: transparent;
  transition: width 0.5s ease, background-color 0.5s ease;
  width: 0;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}
.rts___tab___selected {
  // color: #000 !important;
  position: relative;
  width: 100% !important;
  // background: transparent !important;
  box-shadow: none !important;
}
.rts___tab___selected::after {
  background: var(--rts-primary-color);

  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
}
.rts___nav___btn:hover {
  background-color: unset !important;
}
.rts___nav___btn:hover > svg {
  stroke: rgba(0, 0, 0, 0.6) !important;
}

@media (max-width: 991.98px) {
  .rts___tabs___container {
    padding: 0 !important;
  }
}
@media (max-width: 767.98px) {
  .rts___tab {
    padding: 5px !important;
  }
}

.auction-menu-select {
  .css-1im77uy-control,
  .css-14cdt5o-control,
  .css-93r95-control {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .css-1u9des2-indicatorSeparator {
    display: none;
  }
  .css-1im77uy-control:hover,
  .css-14cdt5o-control:hover,
  .css-93r95-control:hover {
    border: none;
    box-shadow: none;
  }
  .css-10wo9uf-option {
    color: #000;
  }
  .css-1dimb5e-singleValue {
    color: #bbb5c2 !important;
  }
  @media (max-width: 920px) {
    .css-1fdsijx-ValueContainer {
      padding: 2px 0;
    }
  }
}
.auction-menu-select.active-menu {
  .css-1dimb5e-singleValue {
    color: #ffffff !important;
  }
}
.raffle-details {
  p,
  li {
    text-align: left;
  }
  ol {
    padding-left: 0;
  }
  li {
    margin-bottom: 5px;
  }
}
